<template>
  <div class="modal-news" v-if="show">
    <div class="modal-news-content">
      <span @click="close" class="modal-news__close-button">×</span>
      <span class="news-published">{{ selectedNews.published}}</span>
      <h2>{{ selectedNews.title }}</h2>
      <p v-html="selectedNews.content"></p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selectedNews: Object,
    show: Boolean,
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style>

/* Глобальные стили для ссылок */
a {
  text-decoration: underline; /* Убрать подчеркивание */
  color: #b490f1; /* Задать цвет текста для ссылок */
}

.modal-news {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  color: #3d3a3a;
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  width: 100%;
}

.modal-news-content {
  background-color: #fefefe;
  margin: 10% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  position: relative;
  border-radius: 5px;
  top: 80px;
  font-family: "Montserrat", sans-serif;
}
.modal-news-content h2 {
  padding-top: 20px;
  font-weight: bolder;
}

.modal-news-content p {
  padding-top: 10px;
  font-weight: initial;
  line-height: 1.3;
}

.modal-news__close-button {
  color: #aaa;
  position: absolute;
  right: 10px;
  top:10px;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.modal-news__close-button:hover,
.modal-news__close-button:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.news-published{
  font-size: 12px;
  text-align: left;
  position: absolute;
  font-weight: 600;
}
</style>
