<template>
  <div class="feed">
    <div class="feed__title">
      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
        <path fill="currentColor" d="M22.75 24H5.25a3.25 3.25 0 0 1-3.245-3.066L2 20.75V6.25a2.25 2.25 0 0 1 2.096-2.245L4.25 4h15.5a2.25 2.25 0 0 1 2.245 2.096L22 6.25V7h1.75a2.25 2.25 0 0 1 2.245 2.096L26 9.25v11.5a3.25 3.25 0 0 1-3.066 3.245L22.75 24H5.25h17.5Zm-17.5-1.5h17.5a1.75 1.75 0 0 0 1.744-1.607l.006-.143V9.25a.75.75 0 0 0-.648-.743L23.75 8.5H22v10.75a.75.75 0 0 1-.648.743L21.25 20a.75.75 0 0 1-.743-.648l-.007-.102v-13a.75.75 0 0 0-.648-.743L19.75 5.5H4.25a.75.75 0 0 0-.743.648L3.5 6.25v14.5a1.75 1.75 0 0 0 1.606 1.744l.144.006h17.5h-17.5Zm4.996-9.496c.69 0 1.25.56 1.25 1.25v3.496c0 .69-.56 1.25-1.25 1.25H6.75c-.69 0-1.25-.56-1.25-1.25v-3.496c0-.69.56-1.25 1.25-1.25h3.496ZM14.25 17.5h3.496a.75.75 0 0 1 .102 1.493l-.102.007H14.25a.75.75 0 0 1-.102-1.493l.102-.007h3.496h-3.496Zm-4.253-2.996H7V17.5h2.997v-2.996Zm4.253-1.5h3.496a.75.75 0 0 1 .102 1.493l-.102.007H14.25a.75.75 0 0 1-.102-1.494l.102-.006h3.496h-3.496ZM6.246 8.497h11.5a.75.75 0 0 1 .102 1.493l-.102.007h-11.5a.75.75 0 0 1-.101-1.493l.101-.007h11.5h-11.5Z"/>
      </svg>
        <h1>
          Лента новостей
        </h1>
    </div>
    <div class="feed-content">
      <ul class="feed__list">
        <li v-for="newsItem in this.news" :key="newsItem._id" class="feed__item" @click="openModal(newsItem)">
          <span class="item__published">{{newsItem.published}}</span>
          {{ newsItem.title }}
        </li>
      </ul>
      <pagination :currentPage="currentPage" :totalPages="totalPages" @page-change="changePage" />
      <news-modal :selectedNews="selectedNews" :show="modalVisible" @close="closeModal" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import NewsModal from './NewsModal.vue';
import Pagination from './Pagination.vue';
import {mapActions, mapGetters} from "vuex";
import _ from 'lodash';
import Axios from "axios";
export default {
  data() {
    return {
      lodash: _,
      news:[],
      modalVisible: false,
      selectedNews: null,
      currentPage: 0,
      itemsPerPage: 10,
      totalNews: 0,
      totalPages:0
    };
  },
  mounted() {

    this.getNews();

  },
  computed: {
    ...mapGetters(['isDefaultUser', 'user']),
  },
  methods: {
    ...mapActions([
      'getUserStaticData',
    ]),
    async updateLastReadNews(news)
    {
        await axios.put("/api/user/lastreadnews", {newsId : news._id}, {
          headers: {
            token: this.$store.getters.token
          }
        }).then(res => {
          return;
        }).catch(err => {
      console.error(err.response);
    });
    },
    dateFormat(date){
      date = new Date(date);
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      return `${year}/${month}/${day}`;
    },
    async getNews() {

      let data = [];
      await Axios
          .get(`/api/newsfeed`, {
            headers: {
              token: this.$store.getters.token
            },
            params: {
              limit: this.itemsPerPage,
              page: this.currentPage,
              status_id: 'Активна',
              user_category: (this.isDefaultUser) ? "default" : "megafon"
            }
          })
          .then(res => {
            data = res.data.data.map(item => {
              item.published = this.dateFormat(item.published);
              return item;
            });

            this.totalNews = res.data.total ;
            this.totalPages = Math.ceil(res.data.total / this.itemsPerPage) ;
          })
          .catch(err => {
            console.error(err.response);
          });

      this.news = data;

    },
    openModal(newsItem) {
      this.selectedNews = newsItem;
      this.modalVisible = true;
      this.updateLastReadNews(newsItem);
    },
    closeModal() {
      this.modalVisible = false;
      this.selectedNews = null;
    },
    changePage(page) {
      this.currentPage = page;
      this.getNews();
    },
  },
  components: {
    'news-modal': NewsModal, Pagination
  },
};
</script>

<style scoped>

  .feed-wrapper
  {
    margin-top: 100px;
    padding: 0 40px;
  }
  .feed
  {
    color: #333333;
    padding: 0 20px;
  }

  .feed__title {
    color: #000;
    padding: 65px 0 0 0;
    font-weight: 700;
    font-size: 28px;
    display: flex;
  }
  .feed__title h1{
    padding-left: 5px;
  }


  .feed__list {
    padding: 50px 0 10px;
  }
  .feed__item
  {
    margin:5px 0;
    background-color: rgba(161, 161, 161, 0.1);
    line-height: 1.2;
    border-radius: 5px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    padding: 5px;
  }
  .item__published{
    font-size: 14px;
    font-weight: initial;
    flex: 85px;
    padding: 0 5px;
  }
  .feed__btn{
    background-color: transparent;
    border: none;
    width: 100%;
    text-align: left;
  }
</style>