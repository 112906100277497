<template>
 <NewsFeed/>
</template>

<script>
import NewsFeed from "@/components/game/newsfeed/NewsFeed.vue";
export default {
  name: "NewsMain",
  components:{NewsFeed}
}
</script>

<style scoped>

</style>